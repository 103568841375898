import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Select, fetchLanguages, pubnub } from 'wwtc-sdk';
import './login.css';
import './common/button.css';

function Login() {
    const history = useHistory();
    const [form, setForm] = useState({
        language: null,
        displayName: null,
    });
    const [info, setInfo] = useState({
        languages: [],
        status: 'loading', // loading, resolved, error
    });
    const [isModeratorPresent, setIsModeratorPresent] = useState(false);

    useEffect(() => {
        // use the below declarations when
        // API is working on
        fetchCompanyInfo().then((response) => {
            // Dynamically set the room name based on the pathname respecting the prefix assigned to the client
            window.room = response.data.pubnubRoom + '-' + window.location.pathname.split('/')[2];
            window.languagesPreTranslate = response.data.languagesPreTranslate;
            window.logoHeader = response.data.logoHeader;
            window.logoLogin = response.data.logoLogin;
            window.apiToken = response.data.apiToken;

            // set palette color
            if (!!response.data.theme) {
                setPaletteColor(response.data.theme);
            }

            pubnub.addListener({
                presence: function (event) {
                    var action = event.action;
                    var state = event.state;

                    if (action === 'state-change' && state.role === 'moderator') {
                        setIsModeratorPresent(true);
                    }

                    if (action === 'leave' && state.role === 'moderator') {
                        setIsModeratorPresent(false);
                    }
                },
            });

            pubnub.subscribe({
                channels: [window.room],
                withPresence: true,
            });

            pubnub
                .hereNow({
                    channels: [window.room],
                    includeState: true,
                })
                .then(async (res) => {
                    // If moderator is present in the room
                    // allow to access to invited user
                    const participantsInChannel = res.channels[window.room].occupants;

                    const isModerator = participantsInChannel.find(
                        (part) => part.state && part.state.role === 'moderator',
                    );

                    if (isModerator !== undefined) {
                        setIsModeratorPresent(true);
                    }

                    // to do
                    // get the data and send it to the Languanges API endpoint
                    fetchLanguages().then((languages) => {
                        setInfo({
                            status: 'resolved',
                            languages,
                        });
                    });
                });
        });
    }, []);

    const fetchCompanyInfo = async () => {
        try {
            const response = await fetch(
                'https://api.worldwidetechconnections.com/services/manifest/v1/box',
            );
            return response.json();
        } catch (e) {
            return new Error(e);
        }
    };

    const handleLanguage = (language) => {
        setForm({ ...form, language: language });
    };

    const handleLogin = (e) => {
        e.preventDefault();

        if (validateLogin()) {
            const { displayName, language } = form;
            history.push(`/app`, {
                userName: displayName,
                language: language.value,
                gender: 'male',
                roomName: window.room,
                apiToken: window.apiToken,
                logoHeader: window.logoHeader,
            });
        } else {
            alert('Please fill inputs to continue');
        }
    };

    const validateLogin = () => {
        let valid = true;
        Object.keys(form).forEach((item) => {
            if (!form[item] || form[item] === '') valid = false;
        });
        return valid;
    };

    /**
     * Set color palette to css variables :root (src/index.css)
     * @param {object} theme Color palette properties
     */
    const setPaletteColor = (theme) => {
        document.documentElement.style.setProperty(
            '--main-bg-button-primary',
            theme.bgColorLoginButton,
        );
        document.documentElement.style.setProperty(
            '--main-color-button-primary',
            theme.mainButtonTextColor,
        );
        document.documentElement.style.setProperty('--header-color-text', theme.headerTextColor);
        document.documentElement.style.setProperty('--gradient-primary', theme.gradientPrimary);
        document.documentElement.style.setProperty('--gradient-secondary', theme.gradientSecondary);
        document.documentElement.style.setProperty(
            '--component-header-option',
            theme.textHeaderComponents,
        );

        if (!!theme.primaryColor) {
            document.documentElement.style.setProperty('--main-primary', theme.primaryColor);
        }
    };

    if (info.status === 'loading') {
        return (
            <div className="loader-wrapper">
                <div className="text-center">
                    <img
                        src={`${process.env.PUBLIC_URL}/oval.svg`}
                        className="loader-img"
                        alt="Loader icon"
                    />
                    <h1 className="loader-title">Loading app...</h1>
                </div>
            </div>
        );
    }
    return (
        <div className="wrapper-login">
            <div className="wrapper-content">
                <div className="div-tests-button"></div>
                <div className="wrapper-login-modal">
                    <div className="wrapper-logo">
                        <img src={`${window.logoLogin}`} alt="WWTC" />
                    </div>
                    <form className="form-modal" onSubmit={handleLogin}>
                        <div className="title-welcome">
                            <h2>Welcome</h2>
                        </div>
                        <div className="wrapper-login-form">
                            <div className="login-inputs">
                                <Select
                                    handleChange={handleLanguage}
                                    placeholder="Select language"
                                    data={info.languages}
                                    value={form.language || ''}
                                    showLanguageServices={true}
                                />
                            </div>
                            <div className="login-inputs">
                                <input
                                    className="Input"
                                    type="text"
                                    placeholder="Display name"
                                    value={form.displayName || ''}
                                    onChange={(e) =>
                                        setForm({ ...form, displayName: e.target.value })
                                    }
                                />
                            </div>
                        </div>
                        <div className="wrapper-button-actions">
                            <button
                                onClick={handleLogin}
                                type="submit"
                                className={`Button ${
                                    isModeratorPresent ? 'Button-primary' : 'Button-gray'
                                } Button-block`}
                                disabled={!isModeratorPresent}
                            >
                                connect now
                            </button>
                        </div>
                        {!isModeratorPresent && (
                            <div className="error-message">
                                <p style={{ textAlign: 'center' }}>Moderator is not present</p>
                            </div>
                        )}
                        <div className="wrapper-return-home d-flex justify-content-center">
                            <a className="link" href="/">
                                Return to Home Screen
                            </a>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Login;
