import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Main from './components/Main.js';
import Login from './components/Login.js';

function App() {
    return (
        <Router basename={window.location.pathname.replace(/(\/[^/]+)$/, "")}>
            <Switch>
                <Route path="/app">
                    <Main />
                </Route>
                <Route path="/">
                    <Login />
                </Route>
            </Switch>
        </Router>
    );
}
export default App;
